'use strict';

$(function () {
	var txt_single = typeof lang_validation_js_general_chosen_placeholder_text_single === 'string' ? lang_validation_js_general_chosen_placeholder_text_single : 'Bitte wählen...',
	    txt_multi = typeof lang_validation_js_general_chosen_placeholder_text_multiple === 'string' ? lang_validation_js_general_chosen_placeholder_text_multiple : 'Bitte wählen...',
	    txt_no_rst = typeof lang_validation_js_general_chosen_placeholder_text_no_results === 'string' ? lang_validation_js_general_chosen_placeholder_text_no_results : 'Kein Ergebnis';

	$('.ff-select.is-chosen select').chosen({
		search_contains: true,
		disable_search_threshold: 4,
		placeholder_text_single: txt_single,
		placeholder_text_multiple: txt_multi,
		no_results_text: txt_no_rst
	});
});

